import { useRouter } from 'next/router';
import { useEffect } from 'react';
import ReactGA, { initialize, set, pageview } from 'react-ga';
import { hotjar } from 'react-hotjar';

const useAnalytics = () => {
  const { asPath } = useRouter();

  const env = process.env.NODE_ENV;

  useEffect(() => {
    if (env !== 'production') return;
    hotjar.initialize(1720727, 6);

    initialize('UA-221522287-1');
  }, []);

  useEffect(() => {
    if (env !== 'production') return;
    set({ page: asPath });
    pageview(asPath);
  }, [asPath]);
};

export { useAnalytics };
