import { FC, memo } from 'react';

import styles from './Container.module.css';

interface ContainerProps {
  size: 'small' | 'medium' | 'large';
}

const Container: FC<ContainerProps> = memo(({ size, children }) => {
  return (
    <div className={styles.container} data-size={size}>
      {children}
    </div>
  );
});

export { Container };
