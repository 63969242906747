import { useUpdateAtom } from "jotai/utils";
import { useEffect } from "react";

import { appAtom } from "Atoms";

const useApp = (props) => {
  const setApp = useUpdateAtom(appAtom);

  useEffect(() => {
    setApp(props);
  }, [props]);
}

export { useApp };