import { FC, memo } from 'react';
import Image from 'next/image';

import { Button, Container } from 'Elements';

import styles from './PriceList.component.module.css';

export interface PriceListComponentDataProps {
  priceItems: {
    _uid: string;
    text: string;
    price: string;
    title: string;
    image: { filename: string; alt: string; title: string };
  }[];
}

export interface PriceListComponentProps {
  data?: PriceListComponentDataProps;
  theme?: Record<any, any>;
  variant?: string;
}

const PriceListComponent: FC<PriceListComponentProps> = memo(({ data = {} }) => {
  const { priceItems } = data;
  console.log('PriceListComponent', { data });

  return (
    <div className={styles.wrapper}>
      <Container size="medium">
        <div className={styles.priceItems}>
          {priceItems.map(({ _uid, text, price, title, image }) => (
            <div className={styles.priceItem} key={_uid}>
              <div className={styles.title}>{title}</div>
              <div className={styles.image}>
                <Image
                  src={`${image?.filename}/m/1500x1000`}
                  alt={image?.alt}
                  layout="fill"
                  unoptimized
                />
              </div>
              <p className={styles.text}>{text}</p>

              <div className={styles.price}>{price}</div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
});

export { PriceListComponent };
