import Head from 'next/head';

import { HeaderComponent, FooterComponent } from 'Components';
import { useAnalytics, useApp } from 'Hooks';

// import 'Global/Fonts.css';
import 'Global/Reset.css';
import 'Global/BaseTheme.css';

const App = ({ Component, pageProps }) => {
  useApp(pageProps);
  useAnalytics();

  return (
    <>
      <Head>
        <title>Nazzanuk Components</title>
        <meta name="viewport" content="width=device-width, user-scalable=no" />

        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta name="msapplication-tap-highlight" content="no" />

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />

        <title>Photo Natives | Interior & Architectural Photographers</title>

        <link rel="stylesheet" href="https://use.typekit.net/dnl2qxv.css" />
      </Head>

      <HeaderComponent />
      <Component {...pageProps} />

      <FooterComponent />
    </>
  );
};

App.displayName = 'App';

export default App;
