import Image from 'next/image';
import { FC, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faChevronRight, faChevronLeft } from 'Icons/FontAwesome/Light';

import styles from './ImageSlider.component.module.css';

export interface ImageSliderComponentDataProps {
  images: { filename: string; alt: string; title: string }[];
  title: string;
  _uid: string;
}

export interface ImageSliderComponentProps {
  data: ImageSliderComponentDataProps;
  theme?: Record<any, any>;
  variant?: string;
}

const ImageSliderComponent: FC<ImageSliderComponentProps> = ({ data = {} }) => {
  const { images = [], title, _uid } = data;
  const [currentIndex, setCurrentIndex] = useState(0);

  const goNext = () => setCurrentIndex((i) => (i + 1 + images.length) % images.length);
  const goPrev = () => setCurrentIndex((i) => (i - 1 + images.length) % images.length);

  const handlers = useSwipeable({
    onSwipedLeft: goNext,
    onSwipedRight: goPrev,
    onTap: goNext,
    trackTouch: true, // track touch input
    trackMouse: true, // track mouse input
  });

  if (!images.length) return null;

  return (
    <div
      className={styles.wrapper}
      key={_uid + '-imageSliderComponent'}
      data-key={_uid + '-imageSliderComponent'}
    >
      <div className="container">
        <div className={styles.title} hidden={!title}>
          {title}
        </div>
      </div>
      <div className={styles.images}>
        {images.map((image, index) => (
          <div
            key={image.filename + '-imageSlider'}
            className={styles.image}
            data-relative-position={index - currentIndex}
            data-position={index}
            style={
              {
                '--relativePosition': index - currentIndex,
                '--position': index,
              } as any
            }
          >
            <div className={styles.img} {...handlers}>
              <Image
                src={`${image?.filename}`}
                unoptimized
                priority={index === 0}
                layout="fill"
                alt={image.alt}
                title={image.title}
              />
            </div>
            <div className={styles.nav}>
              <div className={styles.navItem} onClick={goPrev}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </div>
              <div className={styles.navItem} onClick={goNext}>
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            </div>
            <div className={styles.imageContent}>
              <div className={styles.imageTitle} hidden={!image.title}>
                {image.title}
              </div>
              <div className={styles.imageDescription} hidden={!image.alt}>
                {image.alt}
              </div>
            </div>
          </div>
        ))}

        <div className={styles.placeholder} />
      </div>
    </div>
  );
};

export { ImageSliderComponent };
