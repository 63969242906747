import { useRouter } from 'next/router';
import axios from 'axios';
import React, { FC, useState } from 'react';
import { event } from 'react-ga';

import { Container } from 'Elements';

import styles from './Contact.component.module.css';

const ContactComponent: FC<any> = ({ title, text }) => {
  const { push } = useRouter();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('');

  const canSubmit =
    name.length > 0 && email.length > 0 && message.length > 0 && email.includes('@');

  const onSubmit = async (e: any) => {
    try {
      const response = await axios.post('/api/contact', { name, email, message, subject });

      event({ category: 'Contact form', action: 'Submit success', nonInteraction: true });
      console.log('contact form success', { response });

      setMessage('');
      setSubject('');

      push('/thank-you');
    } catch (err) {
      console.log(err);
    }

    event({ category: 'Contact form', action: 'Submit attempt' });
  };

  return (
    <div className={styles.wrapper}>
      <div className="container">
        <div className={styles.title} hidden={!title}>
          {title}
        </div>
      </div>

      <Container size="small">
        <p className={styles.text} hidden={!text}>
          {text}
        </p>
      </Container>

      <Container size="large">
        <div className={styles.innerWrapper}>
          <div className={styles.form}>
            <div className={styles.formItem}>
              <input
                className={styles.formInput}
                type="text"
                name="name"
                placeholder="Name*"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className={styles.formItem}>
              <input
                className={styles.formInput}
                type="email"
                placeholder="Email*"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className={styles.formItem}>
              <input
                className={styles.formInput}
                type="text"
                placeholder="Subject"
                name="subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </div>
            <div className={styles.formItem} data-is-max-width>
              <textarea
                className={styles.formInput}
                placeholder="Job description*"
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>

            <div className={styles.formItem}>
              <button
                className={styles.formButton}
                onClick={onSubmit}
                data-is-disabled={!canSubmit}
              >
                Submit
              </button>
            </div>
          </div>

          {/* <div className={styles.block}>
            <div className={styles.blockItem}>
              Call us <i className="fal fa-chevron-right" />
            </div>
            <div className={styles.blockItem}>
              Email us <i className="fal fa-chevron-right" />
            </div>
          </div> */}
        </div>
      </Container>
    </div>
  );
};

export { ContactComponent };
