import { FC } from 'react';

import styles from './RichText.component.module.css';

const RichTextComponent: FC = () => {


  return (
    <div className={styles.wrapper}>
      
    </div>
  );
};

export { RichTextComponent };
