import { FC } from 'react';

import styles from './Front.component.module.css';

export interface FrontComponentDataProps {
  sections: {
    title: string;
    link: string;
    images: { filename: string; alt: string; title: string }[];
  }[];
  _uid: string;
}

export interface FrontComponentProps {
  data: FrontComponentDataProps;
  theme?: Record<any, any>;
  variant?: string;
}

const FrontComponent: FC = () => {
  const sections = [
    {
      images: [
        {
          filename:
            'https://img2.storyblok.com/0x1500/filters:format(jpg)/f/102784/4000x2667/c6291699e7/photo-natives-interior-photographer-wide-shot-canteen-design-warm-natural.jpg',
        },
      ],
    },
    // {
    //   images: [
    //     {
    //       filename:
    //         'https://img2.storyblok.com/0x1500/filters:format(jpg)/f/102784/4000x2667/e2ec7927d4/photo-natives-interior-photographer-blue-interior-design-office.jpg',
    //     },
    //   ],
    // },
    // {
    //   images: [
    //     {
    //       filename:
    //         'https://img2.storyblok.com/0x1500/filters:format(jpg)/f/102784/4000x2667/a5f0207055/photo-natives-interior-photographer-mare-street-workspace-urban-industrial-design-look.jpg',
    //     },
    //   ],
    // },
  ];

  return (
    <div className={styles.wrapper}>
      {sections.map((section, index) => (
        <div className={styles.section}>
          <div className={styles.images}>
            {section.images.map((image, index) => (
              <img src={image.filename} alt="" className={styles.image} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export { FrontComponent };
