import { FC } from 'react';

import styles from './Banner.component.module.css';

export interface BannerComponentDataProps {
  title?: string;
  subtitle?: string;
  _uid: string;
}

export interface BannerComponentProps {
  data: BannerComponentDataProps;
  theme?: Record<any, any>;
  variant?: string;
}

const BannerComponent: FC<BannerComponentProps> = ({ data }) => {
  const { title } = data;

  if (!title) return null;

  return (
    <div className={styles.wrapper}>
      <div className={styles.images}></div>

      <div className={styles.title}>{title}</div>
    </div>
  );
};

export { BannerComponent };
