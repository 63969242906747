import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from 'Icons/FontAwesome/Light';
import Link from 'next/link';
import React, { FC, useState } from 'react';
import { event } from 'react-ga';

import styles from './Header.component.module.css';

const HeaderComponent: FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const onMenuOpen = () => {
    event({ category: 'Menu', action: 'Menu opened' });
    setIsMenuOpen(!isMenuOpen);
  };

  const onContactCLick = () => {
    event({ category: 'Menu', action: 'Contact button clicked' });
  };

  return (
    <>
      <div className={styles.header}>
        <Link href="/" passHref>
          <div className={styles.headerLogo}>
            <div>Photo</div>
            Natives
          </div>
        </Link>

        <div className={styles.menu}>
          <Link href="/office-photography" passHref>
            <a className={styles.headerMenuItem}>Office Photography</a>
          </Link>
          <Link href="/interior-photography" passHref>
            <a className={styles.headerMenuItem}>Interior Photography</a>
          </Link>
          <Link href="/architecture-photography" passHref>
            <a className={styles.headerMenuItem}>Architecture Photography</a>
          </Link>
          <Link href="/about" passHref>
            <a className={styles.headerMenuItem}>About</a>
          </Link>
          <Link href="/price-list" passHref>
            <a className={styles.headerMenuItem}>Prices</a>
          </Link>
          <Link href="/contact" passHref>
            <a className={styles.headerMenuItem} data-is-contact onClick={onContactCLick}>
              Book now
            </a>
          </Link>
          <div className={styles.headerMenuItem} data-is-menu onClick={onMenuOpen}>
            Menu
          </div>
        </div>
      </div>

      <div
        className={styles.sidebar}
        data-is-active={isMenuOpen}
        onClick={() => setIsMenuOpen(false)}
      >
        <Link href="/office-photography" passHref>
          <a className={styles.headerMenuItem}>Office Photography</a>
        </Link>
        <Link href="/interior-photography" passHref>
          <a className={styles.headerMenuItem}>Interior Photography</a>
        </Link>
        <Link href="/architecture-photography" passHref>
          <a className={styles.headerMenuItem}>Architecture Photography</a>
        </Link>
        <Link href="/about" passHref>
          <a className={styles.headerMenuItem}>About</a>
        </Link>
        <Link href="/price-list" passHref>
          <a className={styles.headerMenuItem}>Prices</a>
        </Link>
        <Link href="/contact" passHref>
          <a className={styles.headerMenuItem} onClick={onContactCLick}>
            Book now
          </a>
        </Link>
      </div>
      <div
        className={styles.sidebarBackground}
        onClick={() => setIsMenuOpen(false)}
        data-is-active={isMenuOpen}
      />
    </>
  );
};

export { HeaderComponent };
