import { FC } from 'react';
import { render } from 'storyblok-rich-text-react-renderer';

import { Button, Container } from 'Elements';

import styles from './Text.component.module.css';
import Link from 'next/link';

export interface TextComponentDataProps {
  containerSize: 'small' | 'medium' | 'large';
  richText: string;
  buttonLabel: string;
  buttonLink: any;
  smallBottomMargin?: boolean;
  _uid: string;
}

export interface TextComponentProps {
  data?: TextComponentDataProps;
  theme?: Record<any, any>;
  variant?: string;
}

const TextComponent: FC<TextComponentProps> = ({ data = {} }) => {
  const { containerSize, richText, buttonLabel, buttonLink, smallBottomMargin } = data;
  console.log('TextComponent', { data });

  if (!richText) return null;

  return (
    <div className={styles.wrapper} data-is-small-bottom-margin={smallBottomMargin}>
      <Container size={containerSize}>
        <div>{render(richText)}</div>
        {buttonLabel && (
          <Link href={buttonLink.url || `/${buttonLink.cached_url}`}>
          <a className={styles.button}>
            <Button>{buttonLabel}</Button>
            </a>
            </Link>
        )}
      </Container>
    </div>
  );
};

export { TextComponent };
