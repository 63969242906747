import { FC, memo } from 'react';

import styles from './Button.module.css';

interface ButtonProps {}

const Button: FC<ButtonProps> = memo(({ children }) => {
  return <div className={styles.wrapper}>{children}</div>;
});

export { Button };
