import { FC, memo } from 'react';
import ReactGA, { initialize, set, pageview, event } from 'react-ga';
import Image from 'next/image';
import Link from 'next/link';

import styles from './Promos.component.module.css';
import { Container } from 'Elements';

export interface PromosComponentDataProps {
  title: string;
  image: { filename: string; alt: string; title: string };
  pages: { slug: string; content: any }[];
}

export interface PromosComponentProps {
  data?: PromosComponentDataProps;
  theme?: Record<any, any>;
  variant?: string;
}

const PromosComponent: FC<PromosComponentProps> = memo(({ data = {} }) => {
  const { title, pages } = data;

  const onPromoClick = (label: string) => {
    event({ category: 'Promo', action: 'Click', label });
  };

  pages;

  return (
    <div className={styles.wrapper}>
      <Container size="medium">
        <div className={styles.promos}>
          {pages.map(({ content, slug }) => (
            <Link href={`/${slug}`} key={content._uid}>
              <a
                className={styles.promo}
                key={content._uid}
                onClick={() => onPromoClick(content.title)}
              >
                <div className={styles.image}>
                  <Image
                    src={`${content.featuredImage?.filename}/m/1500x1000`}
                    alt={content.featuredImage?.alt}
                    layout="fill"
                    unoptimized
                  />
                </div>
                <div className={styles.title}>{content.title}</div>
              </a>
            </Link>
          ))}
        </div>
      </Container>
    </div>
  );
});

export { PromosComponent };
