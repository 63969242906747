import Link from 'next/link';
import Image from 'next/image';
import { FC } from 'react';
import { useAtomValue } from 'jotai';

import { footerLinksAtom } from 'Atoms';

import styles from './Footer.component.module.css';

const FooterComponent: FC = () => {
  const footerLinks = useAtomValue(footerLinksAtom) ?? [];

  console.log({ footerLinks });

  return (
    <div className="footer" key="footer">
      <div className={styles.wrapper}>
        <div className="container">
          <div className={styles.items}>
            <div className={styles.title}>
              <Link href="/" passHref>
                <div className={styles.headerLogo}>
                  <div>Photo</div>
                  Natives
                </div>
              </Link>
              <div className={styles.tagline}>
                Interior design, office and workspace photography
              </div>
            </div>

            {footerLinks.map(({ content }) => (
              <div className={styles.list} key={content.title}>
                <div className={styles.footerTitle}>{content.title}</div>
                {content.links.map(({ content: linkContent, full_slug }) => (
                  <Link href={full_slug} key={full_slug}>
                    <a className={styles.footerLink}>{linkContent.title}</a>
                  </Link>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className={styles.footerBottom}>© 2022 Photo Natives</div>
    </div>
  );
};

export { FooterComponent };
