import { FC } from 'react';
import Image from 'next/image';

import { Container } from 'Elements';

import styles from './ImageGallery.component.module.css';

export interface ImageGalleryComponentDataProps {
  title: string;
  images: { filename: string; alt: string; title: string }[];
  containerSize: 'small' | 'medium' | 'large';
  isGreyscale?: boolean;
  isLetterbox?: boolean;
  isSmallBottomMargin?: boolean;
  noWrap?: boolean;
  _uid: string;
}

export interface ImageGalleryComponentProps {
  data?: ImageGalleryComponentDataProps;
  theme?: Record<any, any>;
  variant?: string;
}

const ImageGalleryComponent: FC<ImageGalleryComponentProps> = ({ data }) => {
  const {
    images = [],
    title,
    containerSize,
    noWrap,
    isGreyscale,
    isLetterbox,
    isSmallBottomMargin,
  } = data ?? {};

  console.log('ImageGalleryComponent', { data });

  return (
    <div
      className={styles.wrapper}
      data-is-no-wrap={noWrap}
      data-is-greyscale={isGreyscale}
      data-is-letterbox={isLetterbox}
      data-is-small-bottom-margin={isLetterbox}
    >
      <Container size={containerSize}>
        <div className={styles.title} hidden={!title}>
          {title}
        </div>
        <div className={styles.mosaic}>
          {images.map((image, index) => (
            <div key={image.filename + '-imageGallery'} className={styles.mosaicImage}>
              <Image
                src={`${image?.filename}/m/1500x1000`}
                alt={image?.alt}
                layout="fill"
                unoptimized
              />
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export { ImageGalleryComponent };
